


























import Vue from 'vue';

export default Vue.extend({
  hasBackButton: true,
  data: () => ({
    subscriptions: {},
    filter: {
      from: Vue.$date.now().format('YYYY-MM-DD'),
      to: undefined,
    },
  }),
  computed: {
    columns(): any {
      return [
        { value: this.$t('page.hotelClassification.table.businessName'), field: 'businessName' },
        { value: this.$t('page.hotelClassification.table.classificationStars'), field: 'classificationStars' },
        { value: this.$t('page.hotelClassification.table.classificationExpiryDate'), field: 'classificationExpirationDate', format: (date: string) => this.$format.localDate(date) },
        { value: this.$t('page.hotelClassification.table.classificationComment'), field: 'classificationComment' },
      ];
    },
  },
  methods: {
    gotoDetail(id : string) {
      this.$router.push(`/gs/memberPortal/processes/classify-hotel/${id}`);
    },
    async populateSubscriptionList() {
      const response = await this.$service.api.hotelClassifications.getMyHotelClassificationBusinesses();
      return response;
    },
  },
});
